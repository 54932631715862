import { gql } from "graphql-tag";

export const GET_ALL_ACTION_RISKS = gql`
  query getAllActionRisks {
  risk_management_risk {
    id
    name
    category
    description
    id_responsible_area
    id_user_responsible
    id_user_appraiser
    user_appraiser {
      displayName
      id
    }
    user_responsible {
      displayName
      id
    }
    initial_risk
    state
    created_at

  }
}
`;

export const GET_FILTERED_RISKS = gql`
 query GetFilteredRisks($where: risk_management_risk_bool_exp!) {
  risk_management_risk(
    where: $where
    order_by: { created_at: desc }
  ) {
    id
    name
    category
    description
    initial_risk
    state
    created_at
    user_appraiser {
      displayName
    }
    user_responsible {
      displayName
    }
  }
}
`;

export const GET_ALL_USERS_OWNER = (id = null) => gql`
    query MyQuery($module: String! = "privacy") {
      user_module(
        order_by: { user: { displayName: asc } },
        where: {
          module: {
           module: { _eq: $module }
           modules_roles: { role: { _in: ["pr-respondent", "pr-manager", "pr-dpo", "tenant_admin_pr"] } }
          }
          _or: [
            {
              _and: [
                { blocked: { _eq: false } },
                { user: { disabled: { _eq: false } } }
              ]
            },
            ${id ? `{ user: { id: { _eq: "${id}" } } }` : ""}
          ]
        }
      ) {
        id
        blocked
        user {
          id
          displayName
          email
          disabled
          user_module_roles(where: { module: { module: { _eq: $module } } }) {
            role
            id
            id_module
          }
        }
      }
    }
`;

export const INSERT_RISK = gql`
  mutation insertRisk($risk: risk_management_risk_insert_input!) {
    insert_risk_management_risk_one(object: $risk) {
      area {
        id
        description
      }
      category
      description
      id
      id_responsible_area
      id_user_responsible
      id_user_owner
      name
      initial_risk
      user_responsible {
        id
        displayName
      }
      user_appraiser {
        displayName
        id
      }
    }
  }
`;