import { GET_ALL_USERS_OWNER, GET_FILTERED_RISKS } from "../queries/action_planQ";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { apolloClient, nhost, module } from "@/main";
import {
  SELECT_INITIAL_RISK,
  SELECT_CATEGORY,
  SELECT_EVALUATOR,
  SELECT_OWNER,
  SELECT_AREA_NOT_USER,
  SELECT_RESPONBILITIES,
  SELECT_USERS_BY_ROLE,
  SELECT_AREA,
  SELECT_RESPONSIBLE,
  TASK_OWNER
} from "@/api/queries/dropdowns_configQ";
import { SELECT_USER_TENANT_A} from "../queries/cad_user_modulesQ";
import { provideApolloClient } from "@vue/apollo-composable";

const provideApollo = async (QUERY, params = {}) => {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(QUERY, params, {
      fetchPolicy: "no-cache",
    })
  );
  while (loading.value) {
    await new Promise((r) => setTimeout(r, 10));
  }
  return result;
};


export async function selectUserTenantA() {
  const tenant = nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");
  const result = await provideApollo(SELECT_USER_TENANT_A, {
    id: tenant,
    module: module,
  });
  const data = result?.value?.users?.map((item: any) => ({
    value: { ...item },
    label: item?.email,
  }));
  return data;
}


export const getAllUsersOwner = async (id = null) => {
  const result = await provideApollo(GET_ALL_USERS_OWNER, { id });
  const data = result?.value?.user_module?.map((item: any) => ({
    value: item?.user.id,
    label: item?.user.displayName,
  }));
  return data;
};

export const getPriority = async (id = null) => {
  const result = await provideApollo(SELECT_INITIAL_RISK);
  const data = result?.value?.risk_management_risk?.map((item) => ({
      value: {...item},
      label: item?.initial_risk,
    }));

  return data;
};

export const getTypes = async (id = null) => {
  const result = await provideApollo(SELECT_CATEGORY);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: {...item},
    label: item?.category,
  }));

  return data;
};

export const getEvaluator = async (id = null) => {
  const result = await provideApollo(SELECT_EVALUATOR);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: {...item},
    label: item?.user_appraiser?.displayName,
  }));

  return data;
};

export const getOwner = async (id = null) => {
  const result = await provideApollo(SELECT_OWNER);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: {...item},
    label: item?.user_owner?.displayName,
  }));
  return data;
};

export const getAreaNotUser = async (id_user: string) => {
  const result = await provideApollo(SELECT_AREA_NOT_USER, { id_user });
  const data = result?.value?.area?.map((item: any) => ({
    value: {...item},
    label: item?.description,
  }));
  return data;
}

export const getResponsibilities = async (id_user: string) => {
  const result = await provideApollo(SELECT_RESPONBILITIES, { id_user });
  const data = {
    divider_appraiser: result?.value?.divider_appraiser,
    divider_responsible: result?.value?.divider_responsible,
    divider_respondent: result?.value?.divider_respondent,
  };
  return data;
}

export const getUsersByRole = async (id_user: string = '', role: string = '', risk_areas: number | [] = null ) => {
  const areas = typeof risk_areas === 'number' ? risk_areas : risk_areas?.map((item: any) => item?.risk?.id_responsible_area)
  const conditions = areas ? `{ id_area: {_in: ${areas}} }` : null;
  const result = await provideApollo(SELECT_USERS_BY_ROLE(id_user, role, conditions));
  const data = result?.value?.user_module?.map((item: any) => ({
    value: {...item?.user},
    label: item?.user?.email,
  }));
  return data;
}


export const getResponsible = async (id = null) => {
  const result = await provideApollo(SELECT_RESPONSIBLE);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: {...item},
    label: item?.user_responsible?.displayName,
  }));
  return data;
};

export const getArea = async (id = null) => {
  const result = await provideApollo(SELECT_AREA);
  const data = result?.value?.risk_management_risk?.map((item: any) => ({
    value: {...item},
    label: item?.area?.description,
  }));
  return data;
};

export const getTaskOwner = async (id = null) => {
  const result = await provideApollo(TASK_OWNER);
  const data = result?.value?.risk_management_risk_task?.map((item: any) => ({
    value: {...item},
    label: item?.owner?.displayName,
  }));
  return data;
};

export const submitFormFilter = async (filters) => {
  try {
    const formatISODate = (dateStr, isEndOfDay = false) => {
      const [day, month, year] = dateStr.split("/");  
      const time = isEndOfDay ? "23:59:59" : "00:00:00";  
      const date = new Date(`${year}-${month}-${day}T${time}Z`);  
      return date.toISOString();  
    };

    const whereConditions = {
      _and: []
    };

    if (filters.type?.value?.category) {
      whereConditions._and.push({ category: { _ilike: filters.type.value.category } });
    }

    if (filters.priority?.value?.initial_risk) {
      whereConditions._and.push({ initial_risk: { _ilike: filters.priority.value.initial_risk } });
    }

    if (filters.evaluator?.value?.user_appraiser?.displayName) {
      whereConditions._and.push({
        _or: [
          { user_appraiser: { displayName: { _ilike: filters.evaluator.value.user_appraiser.displayName } } },
          { user_appraiser: { displayName: { _is_null: true } } }
        ]
      });
    }

    if (filters.startDate) {
      whereConditions._and.push({ created_at: { _gte: formatISODate(filters.startDate) } });
    }

    if (filters.endDate) {
      whereConditions._and.push({ created_at: { _lte: formatISODate(filters.endDate, true) } });
    }

    const variables = {
      where: whereConditions
    };

    const { data } = await apolloClient.query({
      query: GET_FILTERED_RISKS,
      variables,
      fetchPolicy: "no-cache"
    });
    
    return data.risk_management_risk;
  } catch (error) {
    console.error("Erro ao buscar os dados filtrados", error);
  }
};

