import { gql } from "graphql-tag";

export const SELECT_CATEGORY = gql`
    query getAllActionRisks {
      risk_management_risk(distinct_on: category) {
        category
      }
    }
`

export const SELECT_INITIAL_RISK = gql`
query getAllActionRisks {
  risk_management_risk(distinct_on: initial_risk) {
    initial_risk
    id
    state
  }
}
`

export const SELECT_EVALUATOR = gql`
query user_appraiser {
  risk_management_risk (distinct_on: id_user_appraiser) {
    user_appraiser {
      displayName
      id
    }
  }
}
`

export const SELECT_OWNER = gql`
query user_owner {
  risk_management_risk(distinct_on: id_user_owner) {
    user_owner {
      displayName
      id
    }
  }
}
`

export const SELECT_AREA_NOT_USER = gql`
query SelectUserAreaNot($id_user: uuid!) {
  area(where: {_not: {user_areas: {id_user: {_eq: $id_user}}}, status: {_eq: "A"}}) {
    id
    description
    status
  }
}`;

export const SELECT_RESPONBILITIES = gql`
query CountResponsibilities($id_user: uuid!) {
  divider_appraiser: risk_management_risk_aggregate(where: {id_user_appraiser: {_eq: $id_user}, state: {_nin: ["Descartada"]}}) {
    aggregate {
      count
    }
  }
  divider_responsible: risk_management_risk_aggregate(where: {id_user_responsible: {_eq: $id_user}, state: {_nin: ["Descartada"]}}) {
    aggregate {
      count
    }
  }
  divider_respondent: risk_management_risk_task_aggregate(where: {id_owner_task: {_eq: $id_user}, state: {_nin: ["Descartada", "Finalizada"]}}) {
    aggregate {
      count
    }
    nodes{
      risk {
       id_responsible_area
      }
    }
  }
}
`;

export const SELECT_USERS_BY_ROLE = (id_user: string, roles: string, user_areas: string) =>  
  gql`query SelectUserByRole($_eq: String! = "risk_management") {
    user_module(order_by: {user: {displayName: asc}}, where: {module: {module: {_eq: $_eq}}, user: {user_module_roles: {role: {_neq: "tenant_admin_rm_bay" ${!!roles ? `, _in: "${roles}"`:''}}, module: {module: {_eq: $_eq}}}, disabled: {_eq: false}${id_user ? `, id: {_neq: "${id_user}"}`: ''} ${user_areas ? `, user_areas: ${user_areas}`: ''} }, blocked: {_eq: false}}) {
      user {
        id
        displayName
        email
        disabled
        phoneNumber
      }
    }
}`;

export const SELECT_RESPONSIBLE = gql`
query user_responsible {
  risk_management_risk(distinct_on: id_user_responsible) {
    user_responsible {
      displayName
      id
    }
  }
}
`

export const SELECT_AREA = gql`
query area {
  risk_management_risk(distinct_on: id_responsible_area) {
    area {
      description
      id
    }
  }
}
`

export const TASK_OWNER = gql`
query owner {
  risk_management_risk_task(distinct_on: id_owner_task) {
      owner {
          displayName
          id
        }
  }
}
`
