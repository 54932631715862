import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { nhost } from "../main";
import { isUserBlocked } from "@/service/blocking";
import { updateRole } from "@/service/roles";

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" },
  },
  {
    path: "/",
    children: [
      {
        path: "/access-denied-m",
        name: "access-denied-m",
        component: () => import("@/components/errors/AccessDeniedM.vue"),
      },
      {
        path: "/access-denied",
        name: "access-denied",
        component: () => import("@/components/errors/AccessDenied.vue"),
      },
    ],
  },
  {
    path: "/",
    // meta: {
    //   requiresAuth: true,
    //   requiresPickedTenant: true,
    //   allowedRoles: ["pr-dpo", "tenant_admin_pr", "pr-manager", "pr-respondent", "tenant_admin_pr_bay"],
    // },
    children: [
      {
        path: "/risk/:cod/task/:id",
        name: "task",
        component: () => import("@/views/Task.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
        meta: { breadcrumb: 'HOME' }
      },
      {
        path: "/config",        
        children: [
          {
            path: "",
            name: "config",
            component: () => import("@/views/ConfigDash.vue"),
            meta: { breadcrumb: 'Configurações' },
          },
          {
            path: ":table",
            name: "config-table",
            component: () => import("@/views/ConfigTable.vue"),
          }
        ]
      },
      {
        path: "/category",
        name: "category",
        component: () => import("@/views/settings/Category.vue"),
        meta: { breadcrumb: 'Categoria do Risco'}
      },
      {
        path: "/risk/:cod",
        name: "risk",
        component: () => import("@/views/Risk.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  nhost.auth.refreshSession();
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  //esta logado
  if (requiresAuth && !isAuthenticated) {
    window.location.href = "/auth#/login";
    return;
  }
  const role = await updateRole();

  if (requiresPickedTenant && !hasPickedTenant) {
    const encodedRedirect = encodeURIComponent(
      `/modules/gestao-de-riscos/#/${to.fullPath as string}`
    );
    window.location.href = `/auth#/setup/setup-choice${encodedRedirect}`;
    return;
  }
  // if ((await isUserBlocked()) && to.fullPath != "/access-denied-m") {
  //   next({ name: "access-denied-m" });
  //   return;
  // }
  // if (
  //   allowedRoles &&
  //   !allowedRoles.includes(role) &&
  //   to.fullPath != "/access-denied"
  // ) {
  //   next({ name: "access-denied" });
  //   return;
  // }
  next();
});
export default router;
