import iconHomeVue from "@/components/icon/icon-home.vue";
import iconClockVue from "@/components/icon/icon-clock.vue";
import iconMapPinVue from "@/components/icon/icon-map-pin.vue";
import iconListCheckVue from "@/components/icon/icon-list-check.vue";
import IconFlag from "@/components/ui/icon/icon-flag.vue";

export interface HistoryItemProps {
  id: number;
  value: string;
  label: string;
  icon: Function;
  metadata: Record<string, string>;
  completed: boolean;
  description?: string;
  status?: 'ended' | 'repproved' | 'approved' | 'in_progress';
}

export interface LinkedTaskProps {
  id: number;
  title: string;
  responsible: string;
  deadline: string;
  status: 'repproved' | 'approved' | 'in_progress';
  details: string;
  metadata: {
    start_date: string;
    end_date: string;
    completed: boolean;
  };
}

export interface TableItemProps {
  Id: number;
  name: string;
  category: string;
  initial_risks: number;
  state: string;
  description: string;
  attachments: string[];
  tasks: LinkedTaskProps[];
  chat: string[];
  history: HistoryItemProps[];
}

// Função para formatar dados da API
export function formatData(payload: any) {
  return payload.map(item => {
    return {
      Id: item.id, 
      name: item.name, 
      category: item.category, 
      description: item.description, 
      initial_risk: item.initial_risk, 
      state: item.state, 
      user_appraiser: item.user_appraiser.displayName, 
      id_user_appraiser: item.user_appraiser.id,
      user_responsible: item.user_responsible.displayName, 
      id_user_responsible: item.user_responsible.id,
      created_at: item.created_at,
      attachments: [], 
      tasks: [], 
      chat: [], 
      history: [], 
    };
  });
}
export const tableCols = [
  { field: "Id", title: "ID", required: true },
  { field: "name", title: "Nome" },
  { field: "category", title: "Categoria" },
  { field: "user_appraiser", title: "Avaliador", customStyles: 'custom-col', formatter: (data) => data?.displayName },
  { field: "description", title: "Descrição" },
  { field: "initial_risk", title: "Risco Inicial" },
  { field: "state", title: "Status" },
];



export const attachmentsData = [
  {
    id: 1,
    title: "Relatorio_Financeiro_T3_2024.pdf",
    type: ".pdf",
    size: "2.5MB",
    created_at: "12-09-2024"
  },
  {
    id: 2,
    title: "Resultados_de_Auditoria_2024.pdf",
    type: ".pdf",
    size: "3.1MB",
    created_at: "15-09-2024"
  },
  {
    id: 3,
    title: "Plano_Orcamentario_2025.docx",
    type: "docx",
    size: "0.8MB",
    created_at: "20-09-2024"
  },
  {
    id: 4,
    title: "Demonstrativo_Financeiro_2024.pdf",
    type: "pdf",
    size: "1.7MB",
    created_at: "25-09-2024"
  },
  {
    id: 5,
    title: "Declaracao_de_Imposto_2024.docx",
    type: "docx",
    size: "1.3MB",
    created_at: "30-09-2024"
  },
  {
    id: 6,
    title: "Planilha_relatorio_mensal_Flavio.xlsx",
    type: "xlsx",
    size: "2.2MB",
    created_at: "05-10-2024"
  },
  {
    id: 7,
    title: "Planilha_relatorio_mensal_Roberto.xlsx",
    type: "xlsx",
    size: "1.4MB",
    created_at: "05-10-2024"
  },
  {
    id: 8,
    title: "Planilha_relatorio_mensal_Marcela.xlsx",
    type: "xlsx",
    size: "2.9MB",
    created_at: "05-10-2024"
  }
]

export const treatmentData = [
  {
    id: 1,
    name: "Desligamento de operador",
    responsable: "Anna",
    deadline: "12-09-2024",
    status: "Criada"
  },
  
]

export const associationData = [
  {
    id: 1,
    title: "Desligamento de operador",
    area: "Recursos",
    responsable: "Anna",
    status: "Ativo"
  },
  {
    id: 2,
    title: "Desligamento de operador",
    area: "Recursos",
    responsable: "Jossi",
    status: "Ativo"
  },
  
]

export const attachmentsData2 = [
  {
    id: 1,
    title: "Relatorio_Financeiro_T3_2024.pdf",
    created_at: "12-09-2024",
    count_files: "2.5MB",
  },
]
