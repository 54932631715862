import { gql } from "graphql-tag";
import { role } from "@/service/roles";


export const SELECT_USER_MODULE = gql`
  query SelectUsers($_eq: String!) {
    user_module(
      order_by: { user: { displayName: asc } }
      where: { 
        module: { module: { _eq: $_eq } } 
        ${
          role !== "tenant_admin_rm_bay"
            ? `user: { user_module_roles: { role: { _neq: "tenant_admin_rm_bay" }, module: { module: { _eq: $_eq } } } }`
            : ""
        }}     
    ) {
      id
      blocked
      user {
        id
        displayName
        email
        disabled
        phoneNumber
        user_areas {
          id
        }
        user_module_roles(where: { module: { module: { _eq: $_eq } } }) {
          role
          id
          id_module
          user_roles {
            userId
            id
            role
          }
        }
      }
    }
    modules(where: { module: { _eq: $_eq } }) {
      id
    }
  }
`;

export const DELETE_CURRENT_USER_ROLES = gql`
mutation deleteAuthUserRoles($id_module: uuid!, $id_user: uuid!) {
  deleteAuthUserRoles(where: {module_roles: {id_module: {_eq: $id_module}}, userId: {_eq: $id_user}}) {
    returning {
      id
      userId
      role
      user {
          id
          displayName
          email
          disabled
          phoneNumber
      }
     }
  }
  delete_user_module_role(where: {id_module: {_eq: $id_module}, id_user: {_eq: $id_user}}) {
    returning {
      id
      id_module
      id_user
      role
      user {
          id
          displayName
          email
          disabled
          phoneNumber
      }
     }
  }
}`;

export const UPDATE_USER_MODULE_STATUS = gql`
  mutation UpdateUserModuleStatus($id: Int!, $blocked: Boolean!) {
    update_user_module_by_pk(
      pk_columns: { id: $id }
      _set: { blocked: $blocked }
    ) {
      id
      id_module
      id_user
      blocked
      user {
          id
          displayName
          email
          disabled
          phoneNumber
      }
    }
  }
`;
export const INSERT_USER_MODULE_ROLE = gql`
  mutation InsertUserModule(
    $object: [user_module_role_insert_input!]!
    $objectUserAndRole: [authUserRoles_insert_input!]!
  ) {
    insertAuthUserRoles(objects: $objectUserAndRole) {
     returning {
      userId
      role
      id
     }
    }
    insert_user_module_role(objects: $object) {
     returning {
      id
      id_module
      id_user
      role
     }
    }
  }
`;

export const UPDATE_USER_MODULE_ROLE = gql`
  mutation UpdateUserModuleRole(
    $id: Int!
    $new_role: String!
    $id_user: uuid!
    $old_role: String!
  ) {
    update_authUserRoles_many(
      updates: {
        where: { userId: { _eq: $id_user }, role: { _eq: $old_role } }
        _set: { role: $new_role }
      }
    ) {
      returning {
        id
        role
        userId
      }
    }
    update_user_module_role_by_pk(
      pk_columns: { id: $id }
      _set: { role: $new_role }
    ) {
      id
      id_module
      id_user
      role
    }
  }
`;

export const SELECT_MODULE_ROLES = gql`
  query ModuleRoles($_eq: String!) {
    modules_roles(
      where: { 
        module: { module: { _eq: $_eq } } 
        ${
          role !== "tenant_admin_rm_bay"
            ? `role: { _neq: "tenant_admin_rm_bay" }`
            : ""
        }
    }) {
      id
      id_module
      role
    }
  }
`;

export const INSERT_NEW_USER = gql`
  mutation InsertNewUser($object: users_insert_input!) {
    insertUser(object: $object) {
      id
      displayName
      phoneNumber
    }
  }
`;
export const INSERT_USER_MODULE = gql`
  mutation InsertUserModule(
    $user_module: user_module_insert_input!
    $user_module_role: [user_module_role_insert_input!]!
    $objectUserAndRole: [authUserRoles_insert_input!]!
  ) {
    insertAuthUserRoles(objects: $objectUserAndRole) {
      returning {
        userId
        role
        id
      }
    }
    insert_user_module_one(object: $user_module) {
      id
      id_module
      id_user
    }
    insert_user_module_role(objects: $user_module_role) {
      returning {
        user {
          id
          displayName
          email
          disabled
          phoneNumber
        }
        id
        id_module
        id_user
        role
      }
    }
  }
`;

export const SELECT_USER_TENANT_A = gql`
  query SelectUserTenant($id: uuid!, $module: String!) {
    users(
      where: {
        tenants_user: { id_tenant: { _eq: $id } }
        disabled: { _eq: false }
         ${
           role !== "tenant_admin_rm_bay"
             ? `_not: {
            user_module_roles: {
              role: { _eq: "tenant_admin_rm_bay" }
            }
          }`
             : ""
         }
      }
    ) {
      id
      displayName
      email
      disabled
      phoneNumber
      user_module_roles(where: { module: { module: { _eq: $module } } }) {
        role
      }
    }
  }
`;

export const UPDATE_RESPONSIILITIES = (object) => gql`
mutation UpdateResponsibilities($id_user_old: uuid!, $id_user_appraiser: uuid, $id_user_responsible: uuid, $id_user_respondent: uuid) {
  ${object?.id_user_appraiser ? `divider_appraiser: update_risk_management_risk(where: {state: {_nin: ["Descartada"]}, id_user_appraiser: {_eq: $id_user_old}}, _set: {id_user_appraiser: $id_user_appraiser}) {
    affected_rows
  }`: ''}
  ${object?.id_user_responsible ? `divider_responsible: update_risk_management_risk(where: {state: {_nin: ["Descartada"]}, id_user_responsible: {_eq: $id_user_old}}, _set: {id_user_responsible: $id_user_responsible}) {
    affected_rows
  }`: ''}
  ${object?.id_user_respondent ? `divider_respondent: update_risk_management_risk_task(where: {state: {_nin: ["Descartada", "Finalizada"]}, id_owner_task: {_eq: $id_user_old}}, _set: {id_owner_task: $id_user_respondent}) {
    affected_rows
  }`: ''}
}`;
